// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
// import './plugins/google-map';
// import 'slick-carousel';
import './plugins/pagination';
import Swiper, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  EffectCoverflow,
  Thumbs,
  Lazy,
} from 'swiper';

Swiper.use([
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
  Lazy,
]);
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

// const spinnerMarkup =
//   '<svg class="spinner" id="loading-spinner" viewBox="0 0 50 50">\n' +
//   '  <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>\n' +
//   '</svg>';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function megaMenu() {
  const toggles = $('.mega-menu-toggle > a');
  if (!toggles.length) return;

  const menuToggle = function (toggle, menu) {
    menu.toggleClass('active');
    toggle.toggleClass('active');
    $('.navbar-wrapper').toggleClass('mega-active');

    if (window.innerWidth < 992) {
      // do nothing
    } else {
      menu.fadeToggle();
    }
  };

  const closeMenus = function (currentToggle, currentMegaMenu) {
    const menus = $('.mega-menu');
    if (!menus.hasClass('active')) return;
    menus.not(currentMegaMenu).removeClass('active');
    toggles.not(currentToggle).removeClass('active');
    $('.navbar-wrapper').removeClass('mega-active');

    if (window.innerWidth < 992) {
      // do nothing
    } else {
      menus.not(currentMegaMenu).fadeOut();
    }
  };
  $('.mega-back').on('click', closeMenus);

  toggles.on('click', function (e) {
    e.preventDefault();
    const $this = $(this);
    const megaMenu = $this.next('.mega-menu');
    closeMenus($this, megaMenu);
    menuToggle($this, megaMenu);
  });

  $(document).on('click', function (e) {
    if (toggles.parent('li').has($(e.target)).length || $(e.target).is(toggles))
      return;
    closeMenus();
  });

  $('.menu-item a').on('click', function () {
    if ($(this).hasClass('active')) return;
    closeMenus();
  });

  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      closeMenus();

      // Close the search as well
      $('.search_bar.search-desk').slideUp(200);
    }
  });

  $('.header .navbar').on('show.bs.collapse', () => {
    $('.navbar').addClass('show');
    $('body').addClass('menu_on');
    $('.topbar-wrap').addClass('d-none');
    $('html').addClass('overflow-hidden');
    $('.navbar-wrapper').addClass('active');
  });
  $('.header .navbar').on('hide.bs.collapse', () => {
    $('.navbar').removeClass('show');
    $('body').removeClass('menu_on');
    $('.topbar-wrap').removeClass('d-none');
    $('html').removeClass('overflow-hidden');
    $('.navbar-wrapper').removeClass('active');
    closeMenus();
  });
}

// Had to be done this way because bootstrap is pretty strange in terms of its js api
function triggerMenuClose() {
  if (!$('.navbar-toggler').hasClass('collapsed')) {
    $('.navbar-toggler').trigger('click');
  }
}

$('.search-form').on('submit', (e) => e.preventDefault());

function ajaxSearch(inputValue) {
  const searchResult = $('.search_result');
  $.ajax({
    // eslint-disable-next-line no-undef
    url: ajax_object.ajax_url,
    type: 'post',
    data: {
      action: 'data_fetch_search',
      keyword: inputValue,
    },
    success: function (data) {
      $('.search_result').html(data);
      searchResult.show();
    },
  });
}

// Header top announcement behavior
function headerAnnouncement() {
  const annoucement = $('.announcement');
  if (!annoucement.length) return;
  const isSeen = sessionStorage.getItem('announcement-seen');
  if (isSeen) return;
  if (!window.location.hash) {
    annoucement.slideDown(200, () =>
      window.scrollTo({ top: 0, behavior: 'smooth' })
    );
  }
  const closeButton = annoucement.find('.announcement-close');
  closeButton.on('click', function () {
    annoucement.slideUp(200);
    sessionStorage.setItem('announcement-seen', true);
  });
}

// Warning on clicking external links
function externalLinksWarning() {
  /* global whitelist_object */
  const whitelist = whitelist_object.urls;
  const whitelistURLs = Array.isArray(whitelist)
    ? whitelist.map((string) => {
        if (!URL.canParse(string)) return false;
        const url = new URL(string);
        return url ? url : false;
      })
    : [];

  const allLinks = $('a').filter(function () {
    const isWhiteListed = whitelistURLs.find(
      (url) => this.hostname === url.hostname
    );
    return (
      this.hostname && this.hostname !== location.hostname && !isWhiteListed
    );
  });

  allLinks.on('click', function (e) {
    const leavingSiteModal = $('#leaving-site-modal');
    if (!leavingSiteModal.length) return;
    e.preventDefault();
    const href = e.currentTarget.href;
    leavingSiteModal.modal();
    const continueButton = leavingSiteModal.find('#leave-site');
    continueButton.off();
    continueButton.on('click', () => {
      window.open(href, '_blank');
      leavingSiteModal.modal('hide');
    });
  });
}

// Post grid ajax
function ajaxPosts(section) {
  // const loadmore = section.find('.js-loadmore');
  const postsRoot = section.find('.post-grid');
  if (!postsRoot.length) return;
  const paginationContainer = section.find('.js-pagination');
  const postType = postsRoot.attr('data-post-type');
  const postsPerPage = postsRoot.attr('data-posts-per-page');
  const sliderRoot = section.find('.js-slider-root');

  const errorMarkup =
    '<p class="h2 ajax-error">Something went wrong. Please, check your connection, refresh the page and try again.</p>';

  let currentPage = 1;
  let isLoading = false;

  function postsPagination(maxPages) {
    paginationContainer.pagination({
      // the number of entries
      total: maxPages ? maxPages : paginationContainer.attr('data-max-pages'),
      // current page
      current: 1,
      // the number of entires per page
      length: 1,
      // pagination size
      size: 2,
      // Prev/Next text
      prev:
        '<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">\n' +
        '  <rect x="-0.5" y="0.5" width="42" height="42" rx="3.5" transform="matrix(-1 0 0 1 42 0)" fill="white" stroke="#E2E6EB"/>\n' +
        '  <path d="M25.1797 31L16.9979 22L25.1797 13" stroke="#0D5CA3" stroke-width="2"/>\n' +
        '</svg>',
      next:
        '<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">\n' +
        '  <rect x="-0.5" y="0.5" width="42" height="42" rx="3.5" transform="matrix(-1 0 0 1 42 0)" fill="white" stroke="#E2E6EB"/>\n' +
        '  <path d="M17.8203 31L26.0021 22L17.8203 13" stroke="#0D5CA3" stroke-width="2"/>\n' +
        '</svg>',

      click: function (options) {
        currentPage = options.current;
        fetchPosts('replace', currentPage, postType, postsPerPage);
      },
    });
  }

  postsPagination();

  function fetchPosts(action, page, postType, postsPerPage) {
    if (isLoading) return;
    isLoading = true;
    const data = {
      action: 'fetch_posts',
      page,
      postType,
      postsPerPage,
    };
    $.ajax({
      type: 'POST',
      // eslint-disable-next-line no-undef
      url: ajax_object.ajax_url,
      dataType: 'json',
      data,
      beforeSend: function () {
        isLoading = true;
        if (action === 'replace') {
          paginationContainer.css('pointer-events', 'none');
          $('html, body').css('scroll-behavior', 'unset');
          $('html, body').animate(
            {
              scrollTop: postsRoot.offset().top - 100,
            },
            800,
            () => $('html, body').attr('style', '')
          );
          postsRoot.css('height', postsRoot.outerHeight());
          postsRoot.children().fadeOut();
          $('.ajax-overlay').addClass('ajax-overlay--active');
        }
      },
      success: function (response) {
        if (action === 'replace') {
          postsRoot.html(response.html);
          sliderRoot.html(response['slider_html']);
          overlaySlider(section);
        }
      },
      complete: function () {
        isLoading = false;
        postsRoot.css('height', 'auto');
        paginationContainer.css('pointer-events', 'unset');
        $('.ajax-overlay').removeClass('ajax-overlay--active');
      },
      error: function (err) {
        // if (action === 'add') currentPage--;
        console.log(err);
        if (action === 'replace') {
          postsRoot.html(errorMarkup);
          paginationContainer.hide();
        }
      },
    });
  }
}

function saveHeaderHeight() {
  const header = $('header.header');
  header.css('--header-height', `${header.outerHeight()}px`);
}

function getHash() {
  const hash = window.location.hash;
  if (!hash) return;
  // remove hash from URL
  history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search
  );
  return hash;
}

// It's important to get and remove the hash from the URL ASAP to prevent the page snapping to the element with hashed ID
const hash = getHash();

function scrollToHashedElement(hash) {
  const element = $(hash);
  if (!element.length) return;
  $('html, body').animate(
    {
      scrollTop: element.offset().top - 100,
    },
    800,
    () => {
      const collapseElement = element.find('.collapse');
      if (!collapseElement.length) return;
      collapseElement.collapse('show');
    }
  );
}

function positionLinkArrow() {
  const linkText = $('.link-text');
  if (!linkText.length) return;
  linkText.each(function () {
    const $this = $(this);
    const arrow = $this.find('.link-icon');
    arrow.css('left', `${$this.width() + 15}px`);
  });
}

function overlaySlider(section) {
  if (!section.length) return;
  const postGrid = section.find('.post-grid');
  // eslint-disable-next-line no-unused-vars
  const postType = postGrid.attr('data-post-type');
  const overlay = section.find('.js-slider-overlay');
  const closeButton = overlay.find('.overlay-close');
  const backdrop = overlay.find('.slider-overlay-backdrop');
  const postItems = section.find('.js-preview');

  function openOverlayHandler() {
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    $('html,body').addClass('overflow-hidden');
    $('body').css('padding-right', `${scrollBarWidth}px`);
    overlay.css('padding-right', `${scrollBarWidth}px`);
  }

  function closeOverlay() {
    overlay.fadeOut(() => {
      $('html,body').removeClass('overflow-hidden');
      $('body').css('padding-right', '0');
      overlay.css('padding-right', '0');
    });
  }

  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      closeOverlay();
    }
  });

  backdrop.on('click', () => {
    overlay.fadeOut(() => {
      closeOverlay();
    });
  });

  closeButton.on('click', () => {
    overlay.fadeOut(() => {
      closeOverlay();
    });
  });

  /* eslint-disable */
  if (postType === 'gallery') {
    postItems.each(function () {
      const $this = $(this);
      const overlay = $this.next('.js-slider-overlay');
      const galleryMainSlider = overlay.find('.gallery-main.swiper');
      const galleryNavSlider = overlay.find('.gallery-nav.swiper');
      const navSlidesPerView = $this.attr('data-images-count');
      console.log('test', navSlidesPerView);
      let galleryNav, galleryMain, init;

      if (galleryMainSlider.length && galleryNavSlider.length) {
        galleryNav = new Swiper(galleryNavSlider.get(0), {
          init: false,
          spaceBetween: 9,
          slidesPerView: +navSlidesPerView >= 3 ? 3 : navSlidesPerView,
          freeMode: true,
          watchSlidesProgress: true,
          breakpoints: {
            768: {
              slidesPerView: +navSlidesPerView <= 6 ? navSlidesPerView : 6,
            },
          },
        });

        galleryMain = new Swiper(galleryMainSlider.get(0), {
          init: false,
          spaceBetween: 9,
          slidesPerView: 1,
          autoHeight: true,
          speed: 800,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: galleryNav,
          },
        });

        galleryNav.on('init', () => galleryNavSlider.fadeIn(200));
        galleryMain.on('init', () => {
          galleryMainSlider.fadeIn(200);
          init = true;
        });
        galleryNav.init();
        galleryMain.init();

        $this.on('click', function () {
          if (!init) {
            galleryNav.init();
            galleryMain.init();
          }
          overlay.fadeIn(() => {
            openOverlayHandler();
          });
        });
      }
    });
  }

  if (postType === 'team') {
    let teamSlider;
    teamSlider = new Swiper('.team-slider.swiper', {
      init: false,
      spaceBetween: 9,
      slidesPerView: 1,
      speed: 800,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    let init = false;

    postItems.on('click', function () {
      const $this = $(this);
      const thisId = $this.attr('data-post-id');
      const targetSlideNumber = overlay
        .find(`.swiper-slide[data-post-id="${thisId}"]`)
        .attr('data-slide-number');
      overlay.fadeIn(() => {
        openOverlayHandler();

        if (!init) {
          teamSlider.on('init', () => {
            teamSlider.slideTo(targetSlideNumber);
            init = true;
          });
          teamSlider.init();
        }
        if (init && targetSlideNumber) {
          if (teamSlider) {
            teamSlider?.slideTo(targetSlideNumber, 500, false);
          }
        }
      });
    });
  }
}

function categoryPageNav() {
  const navItems = $('.js-category-nav-item');
  if (!navItems.length) return;
  navItems.each((i, el) => {
    const self = $(el);
    if (!self.hasClass('has-children')) return;
    const parentLink = self.find('.js-category-nav-item-title');
    const submenu = self.find('.js-category-nav-submenu');

    let isSubmenuShown = false;
    parentLink.on('click', function (e) {
      if (!isSubmenuShown) e.preventDefault();
      submenu.slideDown();
      parentLink.addClass('active');
      isSubmenuShown = true;
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  megaMenu();
  headerAnnouncement();
  externalLinksWarning();
  scrollToHashedElement(hash);
  saveHeaderHeight();
  categoryPageNav();

  $('a[href*=#]').on('click', function (e) {
    const hash = this.hash;

    if (
      hash !== '#' &&
      hash.length > 1 &&
      this.pathname === window.location.pathname
    ) {
      e.preventDefault();
      const target = $(hash);
      if (!target.length) return;
      const accordions = $('.collapse');
      if (window.innerWidth < 992) {
        triggerMenuClose();
      }

      if (
        accordions.length &&
        accordions.hasClass('show') &&
        target.has('.collapse').length
      ) {
        let open = false;
        accordions.on('hidden.bs.collapse', () => {
          if (open) return;
          $('html, body').animate(
            {
              scrollTop: target.offset().top - 100,
            },
            800,
            () => {
              const collapseElement = target.find('.collapse');
              if (!collapseElement.length) return;
              collapseElement.collapse('show');
              open = true;
            }
          );
        });
        accordions.each((i, el) => $(el).collapse('hide'));
      } else {
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 100,
          },
          800,
          () => {
            const collapseElement = target.find('.collapse');
            if (!collapseElement.length) return;
            collapseElement.collapse('show');
          }
        );
      }
    }
  });

  $('.js-trigger-download-apps-modal').on('click', (e) => {
    e.preventDefault();
    $('#download-apps-modal').modal();
  });

  $('.js-trigger-newsletter-modal').on('click', (e) => {
    e.preventDefault();
    $('#newsletter-modal').modal();
  });

  $('.js-trigger-form-modal').on('click', (e) => {
    e.preventDefault();
    $('#form-modal').modal();
  });

  // Testimonials slider
  // eslint-disable-next-line no-unused-vars
  const testimonialsSlider = new Swiper('.testimonials-slider.swiper', {
    loop: true,
    speed: 800,
    autoplay: {
      delay: 3000,
    },
  });

  $('.js-post-grid-section').each(function () {
    const $this = $(this);
    ajaxPosts($this);
    overlaySlider($this);
  });

  // Search
  $('#content_toggle').on('click', function () {
    $('.search_bar.search-desk')
      .toggleClass('open')
      .slideToggle(200, false, function () {
        if ($(this).hasClass('open')) {
          $(this).find('input[type="text"]').trigger('focus');
        }
      });
    return false;
  });

  const inputSearch = $('.input_search');
  let timer;
  inputSearch.on('input', function () {
    clearTimeout(timer);
    inputSearch.not($(this)).val($(this).val());
    if ($(this).val().length > 2) {
      timer = setTimeout(() => {
        ajaxSearch($(this).val());
      }, 350);
    } else {
      $('.search_result').hide();
    }
  });

  /**
   Rates Animation
   */
  function animateNumbers() {
    $('.rate').each(function () {
      const $this = $(this);
      const initialValue = $this.text();
      $this.prop('Counter', 0).animate(
        {
          Counter: $this.text(),
        },
        {
          duration: 1000,
          easing: 'swing',
          step: function () {
            $this.text(this.Counter.toFixed(2)); // Adjust the decimal places as needed
          },
          complete() {
            $this.text(initialValue);
          },
        }
      );
    });
  }

  // Read more for table description
  $('.btn-read-more').on('click', function (e) {
    e.preventDefault();
    const button = $(this);
    const tableDescription = $(this).prev('.table-description');
    tableDescription.toggleClass('expanded');
    if (button.text() === 'read more') button.text('read less');
    else button.text('read more');
  });
  // Popup form Toggle
  var btnPopup = $('.contacts .contact-column a[href="#popup-contact-form"]');
  btnPopup.on('click', function (e) {
    e.preventDefault();
    $('#popup-contact-form').show().addClass('open'); // Display the popup
  });
  $('#popup-contact-form .close').on('click', function (e) {
    e.preventDefault();
    $('#popup-contact-form').hide().removeClass('open'); // Hide the popup
  });
  // Popup Holidays
  var btnHolidaysPopup = $('#btn-holidays-popup');
  btnHolidaysPopup.on('click', function () {
    $('#holidays-popup').show().addClass('open'); // Display the popup
  });
  $('#holidays-popup .close').on('click', function () {
    $('#holidays-popup').hide().removeClass('open'); // Hide the popup
  });

  $(document).on('click', function (e) {
    // Close Search
    if (
      !$('.search_bar').has($(e.target)).length &&
      !$(e.target).is($('.search_bar'))
    )
      $('.search_bar.search-desk').slideUp(200);

    // Close popups
    const popup = $('.popup');
    if (popup.length && popup.hasClass('open')) {
      const wrapper = popup.find('.wrapper');
      if (
        !wrapper.has($(e.target)).length &&
        !$(e.target).is(wrapper) &&
        !$(e.target).is(btnPopup) &&
        !$(e.target).is(btnHolidaysPopup)
      )
        popup.hide().removeClass('open');
    }
  });
  // slider
  var heroSlider = new Swiper('.hero-slider', {
    // Specify your Swiper container selector here
    direction: 'horizontal', // Slider direction (horizontal or vertical)
    slidesPerView: 1,
    loop: true, // Infinite loop
    speed: 3500, // Transition speed in milliseconds
    autoplay: {
      delay: 4000, // Autoplay delay in milliseconds
      disableOnInteraction: false, // Stop autoplay on user interaction
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true, //controls slides
    },
  });
  heroSlider.init();
  var ratesSlider = new Swiper('.rates-slider-wrapper', {
    // Specify your Swiper container selector here
    direction: 'horizontal', // Slider direction (horizontal or vertical)
    slidesPerView: 1,
    loop: true, // Infinite loop
    speed: 4000, // Transition speed in milliseconds
    on: {
      init: function () {
        animateNumbers(this.slides[this.activeIndex]); // Animate numbers on initialization
      },
      // slideChange: function () {
      //   animateNumbers(this.slides[this.activeIndex]); // Animate numbers on slide change
      // },
    },
    autoplay: {
      delay: 5500, // Autoplay delay in milliseconds
      disableOnInteraction: false, // Stop autoplay on user interaction
    },
    breakpoints: {
      // when window width is >= 769px
      768: {
        slidesPerView: 2,
        spaceBetween: 5,
      },
      // when window width is >= 1020px
      1020: {
        slidesPerView: 3,
        spaceBetween: 12,
      },
      // when window width is >= 1440px
      1440: {
        slidesPerView: 4,
        spaceBetween: 22,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true, //controls slides
    // },
  });
  ratesSlider.init();

  // Calculator
  function calculateMonthlyPayment() {
    const principalInput = $('#principal');
    let principal = parseFloat(principalInput.val());
    const interestRateInput = $('#loan-rate');
    let interestRate = parseFloat(interestRateInput.val()) / 100;
    const loanTermInput = $('#loan-term');
    let loanTerm = parseInt(loanTermInput.val());
    const loanTermValueElement = $('#loan-term-value');

    // Simple validation
    if (principal < 0) principalInput.val(0);
    if (principal >= 999999999999) principalInput.val(999999999999);
    if (interestRate < 0) interestRateInput.val(0);
    if (interestRate >= 99.99) interestRateInput.val(9999);
    principal = parseFloat(principalInput.val());
    interestRate = parseFloat(interestRateInput.val()) / 100;
    loanTerm = parseInt(loanTermInput.val());

    loanTermValueElement.text(loanTerm);

    const monthlyInterestRate = interestRate / 12;
    const denominator = Math.pow(1 + monthlyInterestRate, -loanTerm);

    let monthlyPayment = (principal * monthlyInterestRate) / (1 - denominator);

    if (interestRate === 0) monthlyPayment = principal / loanTerm;

    let result;
    if (
      monthlyPayment > 0 &&
      principal > 0 &&
      interestRate >= 0 &&
      Number.isFinite(monthlyPayment) &&
      !Number.isNaN(monthlyPayment)
    ) {
      result = `$${Math.round(monthlyPayment)}`;
    } else {
      result = '$0';
    }

    $('#monthly-payment').text(result);
  }

  $('.calc-form').on('input', calculateMonthlyPayment);

  // Calculate initially
  calculateMonthlyPayment();
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  //
  // Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if (!$target.length) return;
    const elOffset = $target.offset().top;
    const elHeight = $target.height();
    const windowHeight = $(window).height();
    let offset;

    if (elHeight < windowHeight) {
      offset = elOffset - (windowHeight / 2 - elHeight / 2);
    } else {
      offset = elOffset;
    }
    $('html, body').animate({ scrollTop: offset }, 500);
    return false;
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });
  positionLinkArrow();
  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  positionLinkArrow();

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  saveHeaderHeight();
  positionLinkArrow();

  if (window.innerWidth > 991) {
    triggerMenuClose();
  }
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
